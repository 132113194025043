import { CatalogData } from "~~/types/api/catalog.types";
import { ProductData, VariantData, Material } from "~/types/api/product.types";
import { MaterialCategory } from "@/types/api/bootstrap.types";
import { useGeneralStore } from "~/stores/general";
import { plpTypes, sizesSlugs } from "@/configs";

interface ProductMaterialsWithCategories extends MaterialCategory {
  materials: Material[];
}

export const useProduct = (
  product: CatalogData | ProductData | null,
  plpType?: string,
) => {
  const route = useRoute();
  const localePath = useLocalePathPolyfill();
  const { bootstrap } = useGeneralStore();

  const productInfo = ref<ProductData | CatalogData | null>(product);
  const configurationInfo = ref<VariantData | null>(null);
  const dynamicProductTitle = ref("");

  const productTitle = computed<string>(() => {
    return (
      configurationInfo.value?.currentTranslation?.title ||
      dynamicProductTitle.value ||
      (productInfo.value as CatalogData)?.variant_plp_image?.currentTranslation
        ?.title ||
      (productInfo.value as CatalogData)?.default_config?.currentTranslation
        ?.title ||
      productInfo.value?.currentTranslation?.title ||
      ""
    );
  });

  const productTitlePlp = computed<string>(() => {
    return productInfo.value?.currentTranslation?.title || "";
  });

  const productDescription = computed(() => {
    return (
      configurationInfo.value?.currentTranslation?.description ||
      (productInfo.value as CatalogData)?.variant_plp_image?.currentTranslation
        ?.description ||
      (productInfo.value as CatalogData)?.default_config?.currentTranslation
        ?.description ||
      (productInfo.value as ProductData)?.currentTranslation
        ?.long_description ||
      ""
    );
  });

  const productMaterials = computed(() => {
    return productInfo.value?.material_categories_plp_hover_ids?.map((el) => {
      const materialCategory = bootstrap?.material_categories?.find(
        (categ) => categ.id === el,
      );

      const materials = (productInfo.value?.materials as Material[]) || [];
      return {
        ...materialCategory,
        materials: materials.reduce((acc: Material[], material: Material) => {
          if (material.material_category_id === el) {
            acc.push({
              ...material,
            });
          }
          return acc;
        }, []),
      };
    }) as ProductMaterialsWithCategories[];
  });

  const productPrice = computed(() => {
    return (
      // Number((productInfo.value as CatalogData)?.variant_plp_image?.cost) ||
      // Number(productInfo.value?.default_config?.cost) ||
      // Number(productInfo.value?.base_cost)
      Number(
        configurationInfo.value?.cost ||
          (productInfo.value as CatalogData)?.variant_plp_image?.cost ||
          productInfo.value?.default_config?.cost ||
          productInfo.value?.base_cost ||
          0,
      )
    );
  });

  const productSKU = computed(() => {
    return (
      (productInfo.value as CatalogData)?.variant_plp_image?.sku ||
      productInfo.value?.default_config?.configuration_id ||
      productInfo.value?.default_sku
    );
  });

  const discountValue = computed(() => {
    if (
      !productInfo.value?.sale ||
      !productInfo.value?.sale_value ||
      !productPrice.value
    ) {
      return 0;
    }

    return Number(
      (
        productPrice.value -
        productPrice.value * (+productInfo.value.sale_value / 100)
      ).toFixed(2),
    );
  });

  const productLink = computed(() => {
    const sizeDefaultQuery: { size?: string } = {};
    if (
      productInfo.value?.material_categories?.some((item) =>
        sizesSlugs.includes(item.slug),
      )
    ) {
      sizeDefaultQuery.size = "default";
    }
    return plpType === plpTypes.engagementRing
      ? localePath({
          path: `/product/${productInfo.value?.slug}`,
          query: {
            dsku: route.query.dsku || "",
            dShape: route.query.dShape || "",
            configuration_id: productSKU.value,
            startWith: route.query.startWith || "",

            psku: route.query.psku || "",
            pmaterials: route.query.pmaterials || [],

            dCarat: route.query.dCarat || "",
            pAllowedCarats: route.query.pAllowedCarats || [],
            pAllowedShapes: route.query.pAllowedShapes || [],
            flowType: route.query.flowType || "",

            ...sizeDefaultQuery,
          },
        })
      : localePath({
          path: `/product/${productInfo.value?.slug}`,
          query: {
            configuration_id: productSKU.value,
            ...sizeDefaultQuery,
          },
        });
  });

  const finalProductPriceValue = computed(() => {
    return discountValue.value || productPrice.value || 0;
  });

  const materialCategoriesDictionary = computed(() => {
    return (
      productInfo.value?.material_categories?.reduce(
        (acc, el) => {
          acc[el.sku] = el.slug;
          return acc;
        },
        {} as Record<string, string>,
      ) || {}
    );
  });

  return {
    productMaterials,
    productLink,
    productPrice,
    productSKU,
    productTitle,
    productDescription,
    productInfo,
    configurationInfo,
    discountValue,
    finalProductPriceValue,
    materialCategoriesDictionary,
    dynamicProductTitle,
    productTitlePlp,
  };
};
